import React, { useState } from 'react'

import { Select, Alert } from 'antd'

import './VIPBooking.less'
import VIPP1Assessment from './VIPP1Assessment.json'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import { Button, Popover } from 'antd'
import P1HobartOffice from '../../../styles/vipdrivingassets/P1-Hobart-VIP-Office.jpeg'
import P1NorthHobart from '../../../styles/vipdrivingassets/P1-North-Hobart.jpeg'
import P1Glenorchy from '../../../styles/vipdrivingassets/P1-Glenorchy.jpeg'
import P1Kingston from '../../../styles/vipdrivingassets/P1-Kingstorn.jpeg'
import P1Bellerive from '../../../styles/vipdrivingassets/P1-Bellerive.jpeg'
import P1Sorell from '../../../styles/vipdrivingassets/P1-Sorell.png'
const { Option } = Select

const P1_ASSESSMENT_PLACES = VIPP1Assessment.locations

const VIPP1AssessmentPlaces = ({ data }) => {
    const handleClick = () => {
        console.log('s')
    }
    const [open, setOpen] = useState(false)
    const hide = () => {
        setOpen(false)
    }

    const handleOpenChange = newOpen => {
        setOpen(newOpen)
    }
    return (
        <div className="vip-p1-assessmentplaces sm:p-11">
            <h2 className={'text-center'}>Testing Places</h2>
            <div className="p1-places-holder">
                {P1_ASSESSMENT_PLACES.map(p => {
                    return (
                        <Popover
                            content={
                                <div>
                                    {p.valid && (
                                        <p className="font-bold mt-3">
                                            <a href="/p1-assessment-booking">
                                                Book Now.
                                            </a>
                                        </p>
                                    )}
                                </div>
                            }
                            trigger="click"
                            open={open}
                            onOpenChange={handleOpenChange}
                        >
                            <Button
                                className="p1-places-btn font-bold m-3"
                                style={{ width: '200px', height: '100px' }}
                            >
                                {p.key}
                            </Button>
                        </Popover>
                    )
                })}
            </div>

            <h3 className={'vip-info-p1 p-7'}>
                If you are an anxious driver, you have an oppurtunity to learn
                and take the assessment with the same assessor (Condition apply).
            </h3>
            <h2 className={'p-5 text-center'}>Testing Places Location Map</h2>
            <div className="p1-places-holder flex flex-wrap justify-center">
                <div className={'location-map w-8/12'}>
                    <img src={P1HobartOffice} alt={'Hobart Office'} />
                    <h3 className={'p-3 text-center'}>Hobart ( VIP Office) </h3>
                </div>
                <div className={'location-map w-8/12'}>
                    <img src={P1NorthHobart} alt={'North Hobart'} />
                    <h3 className={'p-3 text-center'}>North Hobart</h3>
                </div>
                <div className={'location-map w-8/12'}>
                    <img src={P1Glenorchy} alt={'Glenorchy'} />
                    <h3 className={'p-3 text-center'}>Glenorchy</h3>
                </div>
                <div className={'location-map w-8/12'}>
                    <img src={P1Kingston} alt={'Kingston'} />
                    <h3 className={'p-3 text-center'}>Kingston</h3>
                </div>
                <div className={'location-map w-8/12'}>
                    <img src={P1Bellerive} alt={'Bellerive'} />
                    <h3 className={'p-3 text-center'}>Bellerive</h3>
                </div>
                <div className={'location-map w-8/12'}>
                    <img src={P1Sorell} alt={'Sorell'} />
                    <h3 className={'p-3 text-center'}>Sorell</h3>
                </div>
            </div>
        </div>
    )
}

export default VIPP1AssessmentPlaces
