import React from "react"
import ButtonLink from "@/components/elements/button-link"
import { getButtonAppearance } from "@/utils/button"
import CardItem from "../elements/CardItem"
import "./CardBlocks.less"
const CardBlocks = ({ data }) => {

  const { direction, cards, heading } = data
  return (
    <div className={"row st-card-block"}>
      {heading && <h1 className={"col-md-2 col-sm-12"} dangerouslySetInnerHTML={{__html:heading}}/>}
      <div className={`col-md-10 col-sm-12 st-card-blocks justify-center card-block-direction-${direction}`}>
        {cards &&
          Array.isArray(cards) &&
          cards.map(cardItem => {
            return <CardItem data={cardItem} />
          })}
      </div>
    </div>
  )
}

export default CardBlocks
